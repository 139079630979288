<template>
  <div id="operation-management">
    <div class="operation-titles"><span>{{schoolName}}</span> 操作日志</div>
    <div class="operation-bor"></div>
    <NForm inline label-placement="left" style="margin-top:20px">
      <NFormItem label="搜索关键词：">
        <NInput
          maxlength="50"
          show-count
          placeholder="请输入账号/操作内容"
          clearable
          v-model:value="formValue['School[search]']"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem label="类型">
        <NSelect
          style="width: 130px;"
          :options="typeOptions"
          clearable
          v-model:value="formValue['school[type]']"
        />
      </NFormItem>
      <NFormItem label="操作时间：">
        <n-date-picker v-model:value="formValue['School[time]']" type="datetimerange" clearable @update:value="ChangeTitle"/>
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
    />
    <!-- :row-key="rowKey" -->
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import teachingTypesMap from "@/enumerators/account-status.js";
import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { PostWebSchoolOperation } from "@/api/school.js";
import { toRaw } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import ownUrlPermission from '@/utils/own-url-permission.js';

import { accountOperationTypeValName } from '@/enums/account-operation-type.js';

const message = useMessage();
const route = useRoute();
const router = useRouter();
const schoolId = route.query.id;
const schoolName = ref(route.query.name);
const typeOptions = Object.keys(accountOperationTypeValName).map(val => ({ label: accountOperationTypeValName[val], value: Number(val) }));

// 总数据
const Datas = ref([])
const columns =  [
    {
      title: "账号",
      key: "admin_username",
    },
    {
      title: "操作内容",
      width: 600,
      key: "content",
    },
    {
      title: '类型',
      width: 130,
      render: ({ type }) => h(
        'span',
        null,
        accountOperationTypeValName[type]
      )
    },
    {
      title: '时间',
      render: row => h(
        NTime,
        {
          time: (Number(row.create_time) || 0) * 1000
        }
      )
    },
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  PostWebSchoolOperation(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list
        updateItemCount(res.data.total)
      }
    })
    .catch((reject) => {});
};

const addLabelRef = ref(null);

// 监控标题输入
function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

// 翻页 搜索参数集
var ParameterSet = {}

// 搜索
function handleSearch () {
  ParameterSet = toRaw(formValue)
  // 操作时间有值时 转化
  let time = ParameterSet['School[time]']
  if(time!=null){
    ParameterSet['School[start_time]'] = time[0]
    ParameterSet['School[end_time]'] = time[1]
  }
  updateList(ParameterSet)
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  page_size: 10,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageChange = (pageSize) => {
  pagination.pageSize = pageSize;
  formValue.pageSize = pageSize;
  pagination.page_size = pageSize;
  formValue.page_size = pageSize;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};

// 分页结束-------------------

const getDefaultFormValue = () => ({
  "School[id]":schoolId,
  "School[search]":'',
  "School[time]": null,
  "School[start_time]": null,
  "School[end_time]": null,
  'school[type]': null,
  page: 1,
  page_size:10,
  pageSize: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);

const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  pagination.pageSize = 10
  handleSearch();
};

defineExpose({
  getSearchParams,
});

updateList(toRaw(formValue));
</script>

<style scoped>
#operation-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.operation-titles{
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.operation-bor{
  width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  border-bottom: 1px solid #efeff5;
}
.operation-titles span{
  color: #3b6fff;
}
</style>